/* Dark modal styles */
.dark-modal .modal-content {
    background-color: #333;
    color: #fff;
  }
  
  .dark-modal .modal-header, .dark-modal .modal-footer {
    border-color: #444;
  }
  
  .dark-modal .modal-body {
    color: #fff;
  }
  
  .tablesorter thead th {
    color: #ffeb3b; /* Bright yellow for the table headers */
  }
  
  .tablesorter tbody td {
    color: #fff;
  }
  
  .tablesorter tr {
    border-bottom: 1px solid #555;
  }
  