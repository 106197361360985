/* UserList.css */

/* Dark Modal Styles */
.dark-modal .modal-content {
    background-color: #333;
    color: #fff;
  }
  
  .dark-modal .dark-modal .modal-footer {
    border-color: #444;
  }
  
  .dark-modal .modal-body  {
    color: #fff;
  }
  .modal-header {
    background-color: white;
  }
  .dark-table thead th {
    color: #ffeb3b; /* Add a brighter color for table headers */
  }
  
  .dark-table tbody td {
    color: #fff;
  }
  
  .dark-table tr {
    border-bottom: 1px solid #555;
  }
  